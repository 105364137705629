/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDS_X1R3FHGDDH-rUzyHztSeoXwBlfqTLI",
  "appId": "1:346729412056:web:cbae5306da7c9db8972ead",
  "authDomain": "schooldog-i-long-ga.firebaseapp.com",
  "measurementId": "G-ZD0HD5197Z",
  "messagingSenderId": "346729412056",
  "project": "schooldog-i-long-ga",
  "projectId": "schooldog-i-long-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-long-ga.appspot.com"
}
